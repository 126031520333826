import React from 'react';
import CollectionsOverview from '../../components/collections-overview/collections-overview';


const ShopPage = () => {
  return(
    <div className='shop-page'>
      <CollectionsOverview/>
    </div>
  )
}

export default ShopPage;
